<template>
    <div class="newpage">
        <myhead id="new_1"  :param="param"/>
        <div style="min-height: 100px;">
            <div style="background-color: #262626;height: 100px;"></div>
        </div>
        <div class="body1200" style="min-height: 1000px">
            <div style="font-size: 20px;font-weight: bold;text-align: left;margin-top: 30px;">活动资讯</div>
            <div style="margin-top: 30px; height:1px;background-color: rgba(0, 0, 0, 1);" > </div>
            <div style="font-size: 30px;font-weight: bold;margin-top: 30px;" v-html="this.res.tb_title">
            </div>
            <div style="font-size: 14px;margin-top: 30px;color: rgba(24,24,24,0.8);">
                时间：{{this.res.tb_create_time}} {{mynbsp10}}浏览量：{{this.res.tb_count_read}} {{mynbsp10}}分享：<img :src="fxwx" style="vertical-align: middle;margin-left: 5px;margin-right: 5px;"><img :src="fxwb" style="vertical-align: middle;">
            </div>
            <div style="font-size: 16px;margin-top: 30px;color: rgba(24,24,24,0.8);text-align: left;line-height: 26px;margin-bottom: 60px;text-indent:2em"
                 v-html="this.res.tb_content">

            </div>
        </div>

    </div>
</template>

<script>
    import myhead from '../components/myhead.vue'
    import { topicBase } from "../hts/api.js"
    export default {

        name: "newpage",
        components: {
            myhead,
        },

        data() {
            return {
                param: {
                    pageindex: 6,
                },
                mynbsp10:'\u3000\u3000\u3000\u3000\u3000',
                mynbsp1:"\u3000",
                space2:"&nbsp;&nbsp;",
                fxwx: require('../assets/fxwx.png'),
                fxwb: require('../assets/fxwb.png'),
/*
                new101: require('../assets/new101.jpg'),
                new102: require('../assets/new102.jpg'),
                new103: require('../assets/new103.jpg'),
                new104: require('../assets/new104.jpg'),
                new105: require('../assets/new105.jpg'),
                new106: require('../assets/new106.jpg'),
                new107: require('../assets/new107.jpg'),*/
                id: this.$route.query.id ,
                res:{},
            }
        },
        mounted(){
            this.getData()
            /*},
            update(){*/
            document.getElementById('new_1').scrollIntoView({
                block: 'start',
                behavior: 'smooth'
            });
        },
        methods: {
            getData () {
                // console.log(this.id)
                topicBase({ id: this.$route.query.id })
                    .then(resData => {
                        this.res=resData.data.rows[0]
                        //alert(res.data.rows[0].tb_content)
                        //alert(data.rows[0].tb_content)
                    })
                    .catch()
            },
        },
        watch: {

        },
    }
</script>

<style lang="css" scoped>
    .newpage {
        /* position: static;
         width: 100%;
         !*min-height: 3000px;*!
         text-align: center;
         background-color: white;
         color: black;
         line-height: 32px;*/

        position: static;
        width: 100%;
        text-align: center;

    }


</style>
